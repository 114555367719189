var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex align-center justify-center" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "greenPrimary" },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass: "d-flex align-center pa-6 bgWalletBlockDark rounded",
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-3",
                  attrs: { size: "80", color: "greenPrimary" },
                },
                [_vm._v(" mdi-check-circle-outline ")]
              ),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "mew-heading-2 mb-2 greenPrimary--text" },
                  [_vm._v(" " + _vm._s(_vm.$t("ens.is-available")) + " ")]
                ),
                _c("div", { staticClass: "mew-heading-2" }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "pa-1 mt-3 mb-7 text-center" }, [
        _vm._v(" " + _vm._s(_vm.$t("ens.request.about-domain")) + " "),
      ]),
      _c("mew-select", {
        attrs: {
          "has-filter": true,
          label: _vm.$t("ens.request.select-duration"),
          items: _vm.items,
          "normal-dropdown": "",
        },
        on: { input: _vm.setDuration },
      }),
      _c("div", { staticClass: "font-weight-bold text-center" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("ens.request.estimated-price")) +
            ": " +
            _vm._s(_vm.rentPriceETH) +
            " " +
            _vm._s(_vm.$t("common.currency.eth")) +
            " (" +
            _vm._s(_vm.rentPriceUSD) +
            ") "
        ),
      ]),
      _c(
        "div",
        { staticClass: "d-flex justify-center mt-6" },
        [
          _c("mew-button", {
            attrs: { title: _vm.$t("common.next"), "btn-size": "xlarge" },
            nativeOn: {
              click: function ($event) {
                return _vm.onClick.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }